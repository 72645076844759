<template>
	<header v-if="headingImage" ref="header" :class="{ subpage: !route.name.startsWith('index') }">
		<div class="hero-image">
			<div class="hero-slider">
				<ul class="slides">
					<li>
						<picture>
							<source
								v-if="headingImage.imagePortrait && headingImage.imagePortraitWebp"
								:srcset="headingImage.imagePortraitWebp"
								media="(max-width: 500px)"
								type="image/webp"
							/>
							<source
								v-if="headingImage.imagePortrait"
								:srcset="headingImage.imagePortrait"
								media="(max-width: 500px)"
							/>
							<source :srcset="headingImage.imageWebp" type="image/webp" />
							<source :srcset="headingImage.image" />
							<img class="slider-image" :src="headingImage.image" :alt="headingImage.imageAlt" />
						</picture>
						<slot name="heroContent">
							<div v-if="headingtitle" class="row hero-content">
								<div class="columns column12">
									<div class="content">
										<p class="hero-title">
											<span>{{ headingtitle }}</span>
										</p>
									</div>
								</div>
							</div>
						</slot>
					</li>
				</ul>
			</div>
			<a v-if="route.name.startsWith('index')" class="scroll-down" @click="scrollDown()">
				<span>{{ defaults[locale].homepage.scrollDownText }}</span>
				<div class="scroll-down-icon">
					<img src="~/assets/images/icon-scroll-down-arrow.png" alt="Scroll" />
					<img src="~/assets/images/icon-scroll-down-border.png" class="icon-border" alt="Scroll" />
				</div>
			</a>
		</div>
	</header>
</template>

<script setup>
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchDefaults();

defineProps({
	headingImage: { type: Object, default: () => {} },
	headingtitle: { type: String, default: '' },
});

const route = useRoute();

const header = ref(null);
const scrollDown = () => {
	const scrollHeight = header.value.clientHeight;
	window.scroll({
		top: scrollHeight,
		left: 0,
		behavior: 'smooth',
	});
};
</script>

<style lang="scss" scoped>
.hero-image,
.hero-slider .slides {
	width: 100%;
	height: 100vh;
}

.subpage .hero-image,
.subpage .hero-slider .slides {
	height: 60vh;
}

.hero-image {
	overflow: hidden;

	.slides li {
		width: 100%;
		position: relative;
		height: 100%;

		&::after {
			content: '';
			display: block;
			z-index: 3;
			position: absolute;
			inset: 0;
			background: rgb(0 0 0 / 35%);
		}

		.row {
			position: absolute;
			top: 50%;
			transform: translate(0, -50%);
			left: 0;
			right: 0;
			z-index: 4;
		}

		.slider-image {
			height: 100%;
			width: 100%;
			object-fit: cover;
			z-index: 2;
			transition: all 0.3s ease-in-out;
			animation:
				zoom-in 9000ms ease-in-out forwards,
				fade-in 800ms ease-out;
		}
	}

	.scroll-down {
		position: absolute;
		bottom: 30px;
		left: 0;
		right: 0;
		margin: 0 auto;
		text-align: center;
		z-index: 4;
		font-weight: 300;
		cursor: pointer;

		.scroll-down-icon {
			position: relative;
			width: 60px;
			height: 60px;
			margin: 0 auto;

			img {
				z-index: 2;
				position: relative;
			}

			.icon-border {
				position: absolute;
				inset: 0;
				z-index: 1;
			}
		}

		&:hover .icon-border {
			animation: spin 7s ease-in-out infinite;
		}

		span {
			display: block;
			width: 100%;
			color: #fff;
			margin: 0 0 5px;
		}

		img {
			width: 60px;
			height: 60px;
			transition: all 0.3s ease-in-out;
		}
	}
}

.hero-content {
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	left: 0;
	right: 0;
	z-index: 4;
	width: 770px;
	max-width: 80%;
	padding: 0;

	.column12 {
		padding: 0;
	}

	.content {
		color: #fff;
		text-align: center;
		margin: 0 auto;
		animation: fade-in 1500ms ease-out;
		font-family: $heading-font-family;
	}

	p {
		margin: 0;
	}

	.hero-title {
		font-size: 3.2em;
		line-height: 120%;
		color: #fff;
		font-weight: 300;
		text-transform: uppercase;
	}

	.hero-subtitle {
		font-weight: 400;
		font-size: 20px;
		line-height: 100%;
		letter-spacing: 0.15em;
		text-transform: uppercase;
		color: #fff;
	}
}

@keyframes zoom-in {
	0% {
		transform: scale(1);
	}

	100% {
		transform: scale(1.08);
	}
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes spin {
	100% {
		transform: rotate(360deg);
		transform-origin: 50% 50%;
	}
}

@media (max-width: 800px) {
	.hero-image .hero-content h2 {
		font-size: 4em;
	}
}

@media (max-width: 600px) {
	.hero-image {
		.hero-content {
			.hero-title {
				font-size: 3em;
			}
		}

		.scroll-down {
			bottom: 80px;
		}

		.slides li {
			.row {
				top: 55%;
			}

			.slider-image {
				margin-top: -3vh;
				height: 103vh;
			}
		}
	}
}

@media (max-width: 500px) {
	.hero-image .hero-content {
		.hero-subtitle {
			font-size: 14px;
		}

		.hero-title {
			font-size: 30px;
		}
	}
}
</style>
